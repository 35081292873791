import CircularProgress from '@mui/material/CircularProgress';
import { format, subDays } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import Col from 'rsuite/Col';
import DateRangePicker from 'rsuite/DateRangePicker';
import Panel from 'rsuite/Panel';
import Row from 'rsuite/Row';
import Stack from 'rsuite/Stack';
import 'rsuite/dist/rsuite.min.css';
import http from '../../api';
import CustomXAxisTick from '../../component/charts/CustomXAxisTick';
import StackedMutiBar from '../../component/charts/barchart/StackedMutiBar';
import { getPredefinedDateRanges, showErrorMessage } from '../../component/table/function';
import './analytics.css';
import StackBarChart from '../../component/charts/barchart/StackBarChart';
import StackBarChartMonthly from '../../component/charts/barchart/StackBarChartMonthly';

function AnalyticsDashboard() {
    const closeSidebar = useSelector(state => state.reducer.closeSidebar);
    const [integrations, setIntegrations] = useState([]);
    const [selectedIntegration, setSelectedIntegration] = useState('all');
    const [apiLoading, setApiLoading] = useState(true);
    const [totalProcessing, setTotalProcessing] = useState(0);
    const [totalSuccess, setTotalSuccess] = useState(0);
    const [totalErrored, setTotalErrored] = useState(0);
    
    // Default date range: last 7 days
    const defaultDateRange = [subDays(new Date(), 7), new Date()];
    const [selectedDateRange, setSelectedDateRange] = useState(defaultDateRange);

    const predefinedRanges = getPredefinedDateRanges();

    const [chartData, setChartData] = useState({
        lststatus: [],
        lstpercent: [],
        lststatusmonthly: [],
    });

    const getIntegrations = async () => {
        setApiLoading(true);
        const body = {
            "SearchText": "",
            "CurrentPage": 1,
            "PageSize": 99999999,
            "SortOrder": 'asc',
            "SortCol": 'integration_name',
        };

        await http.post(`/api/v1/Integration/IntegrationMaster/Get`, body)
            .then((res) => {
                setIntegrations(res?.Value ?? []);
                setApiLoading(false);
            }).catch((error) => {
                showErrorMessage('Something went wrong', '', 3000);
                setApiLoading(false);
            });
    };

    const getChartData = (pieValue = 0) => {
        setApiLoading(true);

        let formattedFromDate = '';
        let formattedToDate = '';
        if (selectedDateRange) {
            const [fromDate, toDate] = selectedDateRange;
            if (fromDate && toDate) {
                formattedFromDate = format(fromDate, 'yyyy-MM-dd');
                formattedToDate = format(toDate, 'yyyy-MM-dd');
            }
        }

        const params = {
            "FromDate": formattedFromDate,
            "ToDate": formattedToDate,
            "integrationid": pieValue,
        };

        http.post(pieValue ? `api/v1/Dashboard/GET?integrationid=${pieValue}` : `api/v1/Dashboard/GET`, params)
            .then(res => {
                setChartData({
                    lststatus: res?.Value?.lststatus,
                    lstpercent: res?.Value?.lstpercent,
                    lststatusmonthly: res?.Value?.lststatusmonthly,
                });
                let totalPr = 0;
                let totalSu = 0;
                let totalEr = 0;
                res?.Value?.lstpercent.forEach(integration => {
                    totalPr += integration.total;
                });
                res?.Value?.lststatus.forEach(integration => {
                    totalSu += integration.success;
                    totalEr += integration.faliure;
                });
                setTotalProcessing(totalPr);
                setTotalSuccess(totalSu);
                setTotalErrored(totalEr);
                setApiLoading(false);
            })
            .catch(error => {
                setApiLoading(false);
            });
    };

    useEffect(() => {
        getIntegrations();
        getChartData(selectedIntegration); // Fetch data for default date range on mount
    }, []);

    useEffect(() => {
        getChartData(selectedIntegration);
    }, [selectedIntegration, selectedDateRange]);

    const handleDateRangeChange = (value) => {
        setSelectedDateRange(value);
    };

    if (apiLoading) {
        return (
            <div className={`mainSection dashboardComponent ${closeSidebar ? 'maxMainSection' : 'minMainSection'} fullscreenLoading`}>
                <CircularProgress color="inherit" />
            </div>
        );
    }

    return (
        <div className={`mainSection dashboardComponent ${closeSidebar ? 'maxMainSection' : 'minMainSection'}`} >
            <div className='mainSectionHeading withAddnew'>
                <span>Dashboard</span>
                <div className="row w-100">
                    <div className="col-4"></div>
                    <div className="col-4">
                        <Stack direction="column" spacing={8} alignItems="flex-start" className="daterange-filter">
                            <DateRangePicker
                                ranges={predefinedRanges}
                                value={selectedDateRange}
                                onChange={handleDateRangeChange}
                                placeholder="Select Date Range"
                                style={{ width: '100%' }}
                            />
                        </Stack>
                    </div>
                    <div className="col-4">
                        <select className='form-select' onChange={(e) => setSelectedIntegration(e.target.value)} value={selectedIntegration}>
                            <option value="all">All</option>
                            {
                                integrations.map((ele, index) => {
                                    return (
                                        <option key={index + 1} value={ele.integration_id} >{ele.integration_name}</option>
                                    );
                                })
                            }
                        </select>
                    </div>
                </div>
            </div>
            <div className='dashboardChartWrapper'>
                <div className="container py-3">
                    <Row className="dashboard-cards">
                        <Col md={6} sm={12}>
                            <Panel bordered header="Total Integrations">
                                <h2>{integrations.length}</h2>
                            </Panel>
                        </Col>
                        <Col md={6} sm={12}>
                            <Panel bordered header="Total Messages">
                                <h2>{totalProcessing}</h2>
                            </Panel>
                        </Col>
                        <Col md={6} sm={12}>
                            <Panel bordered header="Success">
                                <h2>{totalSuccess}</h2>
                            </Panel>
                        </Col>
                        <Col md={6} sm={12}>
                            <Panel bordered header="Failure">
                                <h2>{totalErrored}</h2>
                            </Panel>
                        </Col>
                    </Row>
                </div>
                <div className="container-fluid mt-3">
                    <div className="row chartContainer">
                        <div className={(chartData.lststatus.length > 6 ? "col-sm-12 col-12" : "col-sm-6 col-12")  + " stackChartCont"} style={{ overflowX: 'auto' }}>
                            <StackBarChart chartData={chartData.lststatus} />
                        </div>
                        <div className={(chartData.lststatusmonthly.length > 6 ? "col-sm-12 col-12" : "col-sm-6 col-12")  + " stackChartCont"}>
                            <StackBarChartMonthly chartData={chartData.lststatusmonthly} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AnalyticsDashboard;
